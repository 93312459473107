
// COMPONENTS

// APP
import { defineComponent, onMounted, ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
	name: "ProjectDetails",

	props: {
		projectId: {
			type: String,
			required: true,
		},
		currentUser: Object,
	},

	data() {
		return {
			projectDetailsTabs: {},
			project: {},
			store: useStore(),
			router: useRouter(),
		};
	},
	
	setup() {
		const store = useStore();
		const route = useRoute();
		const router = useRouter();

		onMounted(async () => {
			setCurrentPageTitle("Project");
		});

		const isAdmin = computed(() => store.getters["userRole"] === 1);

		return {
			isAdmin,
		};
	},

	methods: {
		
		init() {
			//store = useStore();
			//router = useRouter();	

			this.projectDetailsTabs = [
				{
					componentName: "ProjectOverview",
					path: `/my-projects/${this.projectId}/overview`,
					tabName: "Overview",
					adminOnly: false,
				},
				{
					componentName: "ProjectAccess",
					path: `/my-projects/${this.projectId}/access`,
					tabName: "Access",
					adminOnly: true,
				}
			];

			this.getProject();
		},

		async getProject() {
		
			let projectItem = await this.store.dispatch(Actions.PROJECTS_ITEM_BY_ID, this.projectId );
			
			if (projectItem != undefined  && projectItem.code == 200) {
				this.project = projectItem.data;
			} else {
				// NO USER FOUND, RETURN BACKWARD
				this.router.go(-1);
			}
		}
	},

	mounted() {
		this.init();
	},


});
